<template>
  <div>
    <a-drawer
      title="选择数据报表模板"
      placement="right"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="560"
    >
      <div class="flex-be pdt20">
        <a-input
          placeholder="请输入模板名称"
          style="width: 300px"
          v-model="attachName"
        />
        <div>
          <a-button type="primary" @click="getTemplateData" size="small">
            查询
          </a-button>
          <a-button style="margin-left: 10px" @click="resetSearch" size="small">
            重置
          </a-button>
        </div>
      </div>
      <p>点击选择，标准模板可加快取数效率：</p>
      <a-spin :spinning="listLoading" tip="Loading...">
        <a-list bordered :data-source="listData">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <div class="flex-be">
              <a class="select-exe" @click="selectTemplate(item)">{{
                item.attachName
              }}</a
              ><a :href="item.attachUrl" download="blog">下载</a>
            </div>
          </a-list-item>
        </a-list>
      </a-spin>
    </a-drawer>
  </div>
</template>
<script>
import _https from "@/api/workOrder";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listData: [],
      attachName: "",
      loadingDown: false,
      listLoading: false,
    };
  },
  mounted() {
    this.getTemplateData();
  },
  methods: {
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    resetSearch() {
      this.attachName = "";
      this.getTemplateData();
    },
    getTemplateData() {
      let payLoad = {
        attachName: this.attachName,
      };
      this.listLoading = true;
      _https
        .getTemplateData(payLoad)
        .then((res) => {
          console.log("1212");
          if (res.code == 200) {
            this.listData = res.data;
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    onClose() {
      this.$emit("closeDraw");
      //   this.visible = false;
    },
    downloadExecel(url) {
      this.loadingDown = true;
      let timer = setTimeout(() => {
        this.loadingDown = false;
        clearTimeout(timer);
      }, 1000);
      window.open(url);
    },
    selectTemplate(value) {
      console.log("value", value);
      const { attachName, attachUrl, attachSize, id } = value;
      let obj = {
        size: attachSize,
        name: attachName,
        uid: id,
        xhr: attachUrl,
      };

      this.$emit("addTemplate", obj, 2);
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-be {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.pdt20 {
  padding-bottom: 20px;
}
.select-exe {
  color: #666;
  &:hover {
    color: #1890ff;
  }
}
</style>
