<template>
  <div>
    <h3>创建工单</h3>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="title" label="工单标题" prop="title">
        <a-input
          v-model="form.title"
          :suffix="titleCount"
          placeholder="请输入标题"
          :maxLength="50"
          @change="changeDes(0)"
          class="new-input"
        />
      </a-form-model-item>
      <a-form-model-item label="关联客户">
        <a-select
          v-model="form.principal_id"
          show-search
          option-filter-prop="children"
          allow-clear
          placeholder="请选择"
          label-in-value
        >
          <a-select-option
            :value="item.id"
            v-for="item in principalList"
            :key="item.id"
          >
            {{ item.principal_name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="数据服务阶段" prop="servicePhase">
        <a-radio-group
          name="radioGroup"
          v-model="form.servicePhase"
        >
          <a-radio
            :value="item.type"
            v-for="item in servicePhaseList"
            :key="item.type"
          >
            {{ item.desc }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="期望交付时间">
        <a-date-picker
          v-model="form.date1"
          show-time
          type="date"
          placeholder="请选择时间"
          style="width: 100%"
        />
      </a-form-model-item>
      <a-form-model-item label="需求描述" prop="description">
        <div class="textarea-wrapper">
          <a-input
            type="textarea"
            v-model="form.description"
            :auto-size="{ minRows: 8, maxRows: 12 }"
            :placeholder="'为让数分衡量计算逻辑，统计口径能明确，工单有迹可查，数据可储存，需麻烦填写数据工单\n'+
'数据工单必填项！！：\n'+
'数据目的：如:大众需求数据进行kpi考核，需要到门店维度的视频和有效直播数，每天需要\n'+
'需求方（提供给哪个品牌/运营自用）：如：提供给大众/运营内部分析数据\n'+
'统计时间：如：2023-01-01至2023-01-02\n'+
'统计账号/品牌范围：如：大众使用风火轮账号\n'+
'必须字段：如：视频播放，直播观看人数\n'+
'特殊指标说明（无可不填）：如有效直播：大于30分钟算有效\n'+
'如果是月报周报日报写明截止时间：如：截止6月3号\n'+
'其他：'"
            :maxLength="2000"
            @change="changeDes(1)"
            class="m-textarea"
          >
          </a-input>
          <span class="m-count">{{ desCount }}</span>
        </div>
      </a-form-model-item>
      <a-form-model-item label="添加附件">
        <div class="select-file">
          <exc-upload
            @handleUrl="handleUrl"
            :limitFileLength="limitFileLength"
            :hasFileList="excList"
            acceptList=""
            :limitSize="20"
          >
          </exc-upload>
          <a-button
            class="select-file-btn"
            style="margin-left: 10px"
            type="primary"
            @click="openDraw"
          >
            选择模版
          </a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button style="margin-right: 10px" @click="resetForm">
          重置
        </a-button>
        <a-button type="primary" @click="onSubmit" :loading="saveLoading">
          确定
        </a-button>
      </a-form-model-item>
    </a-form-model>

    <draw-template
      :visible="showDraw"
      @closeDraw="closeDraw"
      @addTemplate="handleUrl"
    />
  </div>
</template>
<script>
import DrawTemplate from './components/DrawTemplate.vue';
import ExcUpload from '@/components/exc-upload.vue';
import _https from '@/api/workOrder';

export default {
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
      other: '',
      form: {
        title: '',
        principal_id: undefined,
        date1: undefined,
        description: '',
        servicePhase: undefined,
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入工单标题',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: '请输入需求描述',
            trigger: 'blur',
          },
        ],
        servicePhase: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      showDraw: false,
      principalList: [],
      excList: [],
      saveLoading: false,
      limitFileLength: 10,
      titleCount: '0/50',
      desCount: '0/2000',
      servicePhaseList: [],
    };
  },
  components: {
    DrawTemplate,
    ExcUpload,
  },
  created() {
    this.getPrincipals();
    this.getServicePhaseList();
  },
  watch: {},
  methods: {
    getServicePhaseList() {
      _https.getServicePhaseList().then((res) => {
        if (res.code == 200) {
          this.servicePhaseList = res.data || [];
        } else {
          this.servicePhaseList = [];
          this.$message.error(res.message || '获取数据失败');
        }
      });
    },
    openDraw() {
      this.showDraw = true;
    },
    closeDraw() {
      this.showDraw = false;
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const { title, principal_id, date1, description, servicePhase } = this.form;
          // console.log('datadat',date1)
          let expectedFinishDate = date1 ? this.$moment(date1).format(
            'YYYY-MM-DD HH:mm:ss',
          ) : undefined;
          let arr = [];
          this.excList.map((item) => {
            arr.push({
              attachUrl: item.xhr,
              attachName: item.name,
              attachType: item.type,
              attachSize: item.size,
            });
          });
          // console.log("arr", arr);
          let payLoad = {
            moduleId: 1,
            title,
            description,
            customerId: principal_id ? principal_id.key : undefined,
            customerName: principal_id ? principal_id.label : undefined,
            expectedFinishDate,
            attachUrlList: arr,
            servicePhase,
          };
          // console.log(payLoad);
          this.saveLoading = true;
          _https
            .createWordOrder(payLoad)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success('创建工单成功！');
                this.$router.push({
                  path: '/workOrder/record',
                });
              } else {
                this.$message.error(res.message);
              }
            })
            .finally(() => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    changeDes(type) {
      if (!type) {
        this.titleCount = `${ this.form.title.length }/50`;
      } else {
        this.desCount = `${ this.form.description.length }/2000`;
      }
    },
    // 品牌主题
    getPrincipals() {
      this.$api.core.TrainingList.getPrincipals().then((res) => {
        if (Array.isArray(res)) {
          this.principalList = res;
        } else {
          this.principalList = [];
          // console.log("获取主体失败");
        }
      });
    },
    handleUrl(val, type) {
      const { size, name, uid, xhr } = val;
      // console.log("val", val);
      if (type == 3) {
        this.excList = this.excList.filter((item) => {
          return item.uid !== uid;
        });
      } else {
        if (this.excList.length >= 10) {
          this.$message.warning('文件上传数已达上限！');
          return;
        }
        let obj = {
          size,
          name,
          uid,
          xhr,
          type: type ? 2 : 1,
        };
        let judge = [];
        judge = this.excList.filter((item) => {
          return item.uid == uid;
        });
        // console.log(judge, "judge");
        if (judge.length == 0) {
          this.excList.push(obj);
        }
        this.$message.success('添加成功');
      }
      // this.limitFileLength = 10 - this.excList.length;
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.select-file {
  position: relative;

  &-btn {
    position: absolute;
    top: 4px;
    left: 126px;
  }
}

.textarea-wrapper {
  position: relative;
  display: block;

  .m-textarea {
    padding: 8px 12px;
    height: 100%;
  }

  .m-count {
    color: #808080;
    background: #fff;
    position: absolute;
    font-size: 12px;
    bottom: 8px;
    right: 12px;
  }
}
</style>
<style lang="scss">
.new-input {
  .ant-input {
    padding-right: 52px !important;
  }
}
</style>
